.pageWrapper {
}

.mainWrapperWithKeyboard{
    padding-bottom: 300px;
}
.content {
    overflow: auto;
    margin: 0 auto;
    max-width: 475px;
    padding: 16px;
    box-sizing: border-box;
}

.info {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

.info {
    margin-top: 40px;
}

.info h2 {
    max-width: 343px;
    margin-top: 16px;
}

.info p {
    margin-top: 8px;
}

.textArea {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    resize: none;
    outline: none;
    border: none;

    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;

}

.textArea::placeholder {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    color: #A2ACB0;
}

.label {
    margin-top: 32px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: var(--main-text-secondary);
    text-transform: uppercase;

    margin-left: 16px;
    margin-bottom: 8px;
}

.photos {
    /*padding-bottom: 16px;*/
}

.photos > h3 {
    margin-top: 32px;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.photos > p {
    margin-top: 4px;
    margin-bottom: 16px;
    color: #707579;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.footer {

    display: flex;
    flex-direction: column;
    padding: 16px 0;
    width: 100%;;
    gap: 8px;

}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
    /*padding-bottom: 600px;*/
}
