.wrapper {
    display: flex;
    background: white;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    margin: 8px auto 0;
    height: 250px;
}

.picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.picker-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.picker-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.picker-item:last-child {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
}

.dateBlock {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 12px;
    width: 100%;
    height: 44px;
    background: var(--main-bg);
    padding: 0px 16px;
}

.textDate {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: var(--main-text);
}

.wrapperDate {
    display: flex;
    align-items: center;
}

.numDate {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    text-align: right;
    color: var(--main-text-secondary);
    margin-right: 8px;
}

.numDateActive {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    text-align: right;
    color: var(--main-accent);
    margin-right: 8px;
}

.infoText {
    padding: 0px 0px 0px 16px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    letter-spacing: -0.01em;
    color: var(--main-text-secondary);
    margin-top: 8px;
}

.text {
    padding-left: 16px;
    margin-top: 8px;
    color: #707579;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
}
