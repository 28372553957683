.deleteButton {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 14px 18px;
    margin-top: 24px;

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    color: #E53935;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #00000033;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteModal {
    background: white;
    border-radius: 16px;
    width: 100%;
    max-width: 270px;
}

.popupTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
}

.deleteModal p {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4300000071525574px;
    text-align: center;
    padding: 20px 16px;
}

.buttons {
    border-top: 0.3px solid #8080808C;
    display: flex;
}

.buttons button {
    color: #007AFF;
    width: 100%;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4300000071525574px;
    text-align: center;
    padding: 11px 0;
    margin-top: 0;

}

