.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: calc(100vh - 84px);
    max-height: calc(100vh - 84px);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    box-sizing: border-box;
    overflow-y: auto;
}

.topWidget {
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 24px;
    margin-top: 12px;
}

.title {
    font-size: 19px;
    font-weight: 590;
    line-height: 24px;
}

.subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}

.destinationWidget {
    background: white;
    border-radius: 10px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.destination {
    position: relative;
    padding: 11px 40px 11px 0;
    display: flex;
    justify-content: space-between;
}

.destination:first-child {
    border-bottom: 1px solid #54545657;
}

.destination svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
}

.destination span {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;

}
.destination span:first-child{
    padding-right: 8px;
}
.destinationInfo {
    color: #707579;
}

.subText {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #707579;
    align-self: flex-start;
    padding-left: 16px;
}

.bottomWidget {
    background: white;
    border-radius: 10px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.widgetItem {
    position: relative;
    padding: 11px 40px 11px 0;
    display: flex;
    justify-content: space-between;
}

.widgetItem:first-child {
    border-bottom: 1px solid #54545657;
}

.widgetItem svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
}

.widgetItem span {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.recentSearchBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    margin-bottom: 8px;
}

.resultItem {
    min-width: 280px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    background: white;
}

.resultsWrapper {
    width: 100%;
    margin-bottom: 16px;
}

.resultInfo {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    word-break: break-word;
    position: relative;
    width: 100%;
}
.resultInfoText{
    display: flex;
    flex-direction: column;
}
.ratingWrapper {
    display: flex;
    align-items: center;
    word-break: initial;
    padding-left: 10px;
}

.ratingWrapper span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.infoText {
    display: flex;
    gap: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #707579;
    margin-top: 2px;
}

.searchItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.recent{
    padding-left: 16px;
    padding-bottom: 8px;
    color: #707579;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
}
