.reviews {
    background: white;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 475px;
    padding-bottom: 84.5px;
    width: 100%;
    flex: 1;

}
.reviewText{
    word-break: break-word;
}
.reviewsTabs {
    display: flex;
    gap: 12px;
    border-bottom: 1px solid #54545657;
}

.tabItem {
    padding: 12px 16px;
    cursor: pointer;
}

.tabItem p {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #A2ACB0;
    margin: 0;
}

.tabItem.active p {
    color: #007AFF;
    position: relative;
}

.tabItem.active p:before {
    content: "";
    position: absolute;
    top: 30px;
    width: 100%;
    display: block;
    height: 3px;
    border-radius: 4px 4px 1px 1px;
    background: #007AFF;
}

.reviewsList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    height: auto;
    /*overflow-y: auto;*/
}

.reviewItem {
    padding: 16px 32px 16px 0;
    display: flex;
    gap: 16px;
    margin-left: 16px;
    border-bottom: 1px solid #54545657;
}

.reviewerName {
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 4px;
    word-break: break-word;
}

.reviewRating {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.date {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #707579;
}

.noReviewsWrapper {
    flex: 1;

    display: flex;
    justify-content: center;
    align-content: center;
}

.noReviews {
    display: flex;
    height: 200px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.emptyReviews {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #707579;
}
