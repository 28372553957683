.calendarWrapper {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    padding-top: 24px;
}

.bottomPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-width: 100vw;
    border-top: 1px solid #54545657;
    padding: 16px;
    box-sizing: border-box;
}

/*.bottomPart button {*/
/*    padding: 14px 18px;*/
/*    width: 100%;*/
/*    background: #007AFF;*/
/*    font-size: 17px;*/
/*    font-weight: 590;*/
/*    line-height: 22px;*/
/*    color: white;*/
/*    box-sizing: border-box;*/
/*    border-radius: 10px;*/
/*}*/

.buttons {
    width: 100%;
}
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    gap: 8px;
    padding: 16px;
    box-sizing: border-box;
    border-top: 0.33px solid var(--additional-divider);
    width: 100%;
    height: auto;
    background: var(--main-bg);
}
