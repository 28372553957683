.wrapper {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 140px;
}

.title {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    letter-spacing: 0.07000000029802322px;
    text-align: left;
}

.btn {
    color: #007aff;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.tracking {
    display: flex;
    justify-content: space-between;
}

.parcelCards,
.deliveryTripCards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.footer {
    position: fixed;
    bottom: 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-top: 0.33px solid var(--additional-divider);
    padding: 6px 16px 12px 16px;
    width: 100%;
    height: auto;
    background: var(--main-bg);
}

.button {
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
}

.requestsLabel {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.noRequests {
    background: #fff;
    padding: 16px;
    gap: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noRequests p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.23000000417232513px;
    text-align: center;
    color: #707579;
}

.info p{
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
    word-wrap: break-word!important;
}
.info span{
    margin-top: 8px;
    display: flex;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    word-wrap: break-word!important;
}
