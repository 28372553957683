.wrapper {
    background: #ffffff;
    padding: 12px 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about {
    display: flex;
    gap: 16px;
    align-items: center;
    min-width: 0;
    flex-basis: 100%;
}

.info {
    /*max-width: 90%;*/
    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 0;
}

.about img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.name {
    margin-right: 16px;
    /*word-break: break-word;*/
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    display: flex;
    flex-grow: 1; /* Позволяет элементу растягиваться */
    min-width: 0;
    /*align-items: flex-start;*/
    /*max-width: 100%;*/
    /*width: 100%;*/


}
.nameText {
    white-space: nowrap; /* Запретить перенос строк */
    overflow: hidden; /* Скрыть переполнение */
    text-overflow: ellipsis; /* Добавить многоточие */
    max-width: 100%; /* Ограничить максимальную ширину элемента */
}

.place {
    color: #707579;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.23000000417232513px;
    text-align: left;
}

.date {
    color: #707579;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
}

.action {
    display: flex;
    gap: 4px;
    align-items: center;
}

.rating {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.size {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}
