.wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.07999999821186066px;
  text-align: left;
  color: #707579;
}
.wrapper span{
  margin-left: 16px;
}
.content {
  width: calc(100% - 32px);
  color: #000;
  word-wrap: break-word;

  background: #ffffff;
  padding: 16px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
}
