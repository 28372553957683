:root {
  --gradient: #007aff;
  --content--primary: #1b1b1b;
  --neutral-300: #b5b5b5;
  --neutral--0: #fff;
  --content--secondary: #667085;
  --neutral-200: #dbdbdb;
  --base-orange: #fe6b1a;
  --neutral-100: #e9e9e9;
  --base-error: #f31e01;
  --main-bg: #fff;
  --main-text: #000;
  --main-accent: #007aff;
  --main-button-text: #fff;
  --main-bg-secondary: #efeff4;
  --additional-red: #e53935;
  --main-text-secondary: #707579;
  --additional-divider: rgba(84, 84, 86, 0.34);
  --additional-outline: #e9e9ea;
  --additional-secondary-fill: #dde8ff;
  --additional-green: #34c759;
  --main-disabled-tab: #999;
  --additional-plain-bg: rgba(0, 0, 0, 0.04);
  --additional-plain-foreground: rgba(0, 0, 0, 0.8);
  --main-hint: #a2acb0;
  --additional-yellow: #fc0;
  --additional-light-green: #e1f7e6;
  --additional-light-red: #fbe1e1;
  --additional-overlay: rgba(0, 0, 0, 0.2);
  --additional-light-yellow: #fffae6;
}

@font-face {
  font-family: "SF-Pro-Regular";
  src: url("assets/fonts/SF-Pro-Regular.otf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Medium";
  src: url("assets/fonts/SF-Pro-Medium.otf") format("truetype");
  font-weight: 510;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Semibold";
  src: url("assets/fonts/SF-Pro-Semibold.otf") format("truetype");
  font-weight: 590;
  font-display: swap;
  font-style: normal;
}

:root {
  --font-family: "SF Pro", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  background: transparent;
  color: inherit;
}

body {
  overscroll-behavior-block: none;
  overscroll-behavior: none;
  margin: 0;
  background: var(--main-bg-secondary);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  margin: auto;
  max-width: 343px;
}


.input {
  box-sizing: border-box;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  word-wrap: break-word!important;
}
