.wrapper {
  background: #ffffff;
  padding: 12px 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.about {
  display: flex;
  gap: 16px;
  align-items: center;

  flex-basis: 100%;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.place {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
}
.date {
  color: #707579;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: left;
}
.action {
  display: flex;
  gap: 4px;
  align-items: center;
}
