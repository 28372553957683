.pageWrapper {
    background: #EFEFF4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;

}

.content {
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
}

.profileAvatarWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.topInfoWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
    max-width: 100%;
    justify-content: center;
}

.profileName {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    /*max-width: 100%;*/
    white-space: normal;
    word-break: break-word;
    text-align: center;
}

.rating {
    display: flex;
    gap: 4px;
    margin: 0;
}

.rating span {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    display: flex;
    align-items: center;
}

.edit {
    margin-top: 8px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    color: #007AFF;
    cursor: pointer;
}

.bioBlock {
    background: white;
    border-radius: 12px;
}

.bioBlock p {
    word-break: break-word;
    margin: 0;
}

.bio {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0 12px 0;
    margin-left: 16px;
    margin-top: 16px;
}

.bio > p,
.links > p {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.bioDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    padding-right: 12px;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 12px 16px 0;
    margin-left: 16px;
}
.linksAndBio {
    border-top: 0.3px solid #54545657;
}
.links a {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #007AFF;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.optionalBlock {
    margin-top: 20px;
    background: white;
    border-radius: 12px;
    margin-bottom: 24px;
}

.optionItem {
    padding: 0 0 0 16px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.optionItem:last-child .optionItemBlock {
    border-bottom: none;
}

.optionItemBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 13px 0 12px;
    border-bottom: 1px solid #54545657;
}

.optionItemBlock > p {
    margin: 0;
}

.optionItemBlock svg {
    margin-right: 14px;
}

.reviewsWrapper {
    flex: 1;
}

.reviewsWrapper > * {
    max-width: 100%;
    height: 100%;
}
