.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;

  font-family: var(--font-family);
  font-weight: 590;
  font-size: 15px;
  line-height: 133%;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--main-button-text);

  transition: all 0.3s ease;
}
.button:disabled {
  cursor: default !important;
}

.button_small {
  border-radius: 20px;
  height: 36px;
}
.button_medium {
  border-radius: 12px;
  height: 42px;
}

.button_large {
  border-radius: 10px;
  height: 50px;

  font-size: 17px;
  padding: 16px 18px;
}

/* _fill */

.button_fill {
  background: var(--main-accent);
}

.button_fill:hover {
  background: var(--main-accent);
  opacity: 0.85;
}

.button_fill:focus {
  background: var(--main-accent);
  opacity: 0.65;
}

.button_fill:disabled {
  background: var(--main-accent);
  opacity: 0.35;
}

/* _bezeled */

.button_bezeled {
  background: var(--main-bg-secondary);

  color: var(--main-accent);
}
.button_bezeled:hover {
  background: var(--main-bg-secondary);
}
.button_bezeled:focus {
  background: var(--main-bg-secondary);
}
.button_bezeled:disabled {
  background: var(--main-bg-secondary);
  opacity: 0.35;
}

/* _plain */

.button_plain {
  background-color: transparent;

  color: var(--main-accent);
}
.button_plain:hover {
  background: var(--main-accent);
  opacity: 0.03;
}
.button_plain:focus {
  background: var(--main-accent);
  opacity: 0.05;
}

.button_plain:disabled {
  opacity: 0.35;
}

/* _gray */

.button_gray {
  background: var(--additional-plain-bg);

  color: var(--additional-plain-foreground);
}
.button_gray:hover {
  background: var(--additional-plain-bg);
  opacity: 0.85;
}
.button_gray:focus {
  background: var(--additional-plain-bg);
  opacity: 0.65;
}
.button_gray:disabled {
  background: var(--additional-plain-bg);
  opacity: 0.35;
}

/* _outline */

.button_outline {
  border: 1px solid var(--main-accent);
  background-color: transparent;

  color: var(--main-accent);
}
.button_outline:hover {
  opacity: 0.85;
}
.button_outline:focus {
  opacity: 0.65;
}
.button_outline:disabled {
  opacity: 0.35;
}

/* _white */

.button_white {
  background-color: transparent;
  color: var(--additional-plain-foreground);
}

.button_white:hover {
  background: var(--neutral--0);
  opacity: 0.85;
}

.button_white:focus {
  background: var(--neutral--0);
  opacity: 0.65;
}

.button_white:disabled {
  background: var(--neutral--0);
  opacity: 0.35;
}
