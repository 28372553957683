.control {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.control.isVisible img {
    transform: rotate(90deg);
}

.bioBlock {
    background: white;
    border-radius: 12px;
}

.bioBlock p {
    margin: 0;
}

.size {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 12px 0;
    margin-left: 16px;
    border-bottom: 1px solid #54545657;
}

.size > p,
.info > p {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #707579
}

.sizeText {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.info {
    display: flex;
    flex-direction: column;
    /*padding: 16px 12px 16px 0;*/
    /*margin-left: 16px;*/
}

.infoText {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    word-break: break-word;
    word-wrap: break-word;
}

.sizeWidgetBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-top: 12px;

    /*border-top: 1px solid #54545657*/
}

.widgetText {
    display: flex;
    align-items: center;
    gap: 6px;
}

.totalPrice {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
}

.price {
    padding-right: 16px;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.row {
    display: flex;
    justify-content: space-between;
}
.wrapper{
    display: flex;
    flex-direction: column;
}
.title {
    text-transform: uppercase;
    padding-left: 16px;
    padding-bottom: 8px;
    color: #707579;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
    margin: 0;
}
.info {
    margin-top: 16px;
}
.info span{

    display: flex;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
}
