.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.datepicker-modal {
  position: fixed!important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.buildNumber{
  position: fixed;
  z-index: 99999999999;
  top: 0;
  right: 0;
  background: rgba(27, 27, 27, 0.36);
  color: white;
  border-radius: 8px;
  padding: 6px;
}
body {

  overflow: hidden!important; /* Запретить прокрутку страницы */
}
.scroll-container {
  overscroll-behavior: none;
  height: 100vh; /* Задайте нужную высоту */
  overflow-y: auto; /* Включите вертикальную прокрутку */
  /*-webkit-overflow-scrolling: touch; !* Для плавной прокрутки на iOS *!*/
}
