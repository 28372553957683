.input {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0px 16px;
  width: 100%;
  height: 44px;
  border: none;

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-text);
}

.input::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-hint);
}

.input:focus {
  border: none;
  outline:none;
}

.input.error {
  border: 1px solid #ff4b4b;
}

.errorMessage {
  margin-top: 16px;
  font-size: 14px;
  color: #ff4b4b;
}
