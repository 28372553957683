.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    box-sizing: border-box;
}

.parcelItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.parcelSizeItem {
    overflow: hidden;
    background: white;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.parcelSizeItem.active {
    border: 1px solid #007AFF;
}

.parcelItem {
    display: flex;
}

.parcelInfo {
    position: relative;
    margin: 8px 0px;
    padding: 0px 24px 0px 8px;
    border-left: 1px solid #54545657;
    width: 100%;
}

.inputWrapper {
    position: absolute;
    top: 4px;
    right: 12px;
}

.bottomBlock {
    width: 100%;
    padding: 16px 16px 32px 16px;
}
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 16px;
    box-sizing: border-box;
    border-top: 0.33px solid var(--additional-divider);
    width: 100%;
    height: auto;
    gap: 8px;
    background: var(--main-bg);
}
