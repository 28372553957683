.wrapper {
    position: absolute;
    right: 16px;
    top: 0;
    z-index: 10;
}

.content {
    position: relative;
    box-shadow: 0px 0px 32px 0px #00000033;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.languageWrapper {
    position: absolute;
    right: 8px;
    bottom: -70%;
    z-index: 11;

}

.languageContent {
    box-shadow: 0px 0px 32px 0px #00000033;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 62px;
}

.item {
    display: flex;
    padding: 11px 16px;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}
.item p{
    display: flex;
    align-items: center;
}
.activeItem{
    color: #007AFF;
}
.item:not(:last-child) {
    border-bottom: 0.5px solid #54545657
}
