.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    padding: 16px;
}
.info{
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 590;
    line-height: 25px;
    letter-spacing: -0.44999998807907104px;
    text-align: center;

}
.mainWrapperWithKeyboard{
    padding-bottom: 330px;
}

.textArea {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    height: 84px;
    width: 100%;
    resize: none;
    background: var(--main-bg);
    outline: none;
    border: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
}
.label {
    width: 100%;
    margin-top: 32px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: var(--main-text-secondary);
    text-transform: uppercase;
    text-align: left;
    margin-left: 16px;
    margin-bottom: 8px;
}

.textArea::placeholder {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: #A2ACB0;
}
.footer {

    display: flex;
    flex-direction: column;

    padding: 16px 0;
    width: 100%;
    height: auto;
    gap: 8px;

}
.button {
    width: 100%;
    display: flex;
    justify-content: center;
}
