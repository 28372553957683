.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 16px;

    box-sizing: border-box;
}
.popularDestinations{
    width: 100%;
    max-width: 352px;
    margin: 0 auto;
    padding-left: 16px;
    margin-top: 16px;
    color: #707579;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;

}
.searchBar {
    width: 100%;
    box-sizing: border-box;
    min-width: 100vw;
    padding: 6px 16px 16px;
    background: white;
    position: relative;
}

.searchIcon,
.clearIcon {
    position: absolute;
    top: 14px;
}

.searchIcon {
    left: 24px;
}

.clearIcon {
    right: 32px;
}

.searchBar input {
    padding: 7px 28px 7px 32px;
    position: relative;
    background: #7878801F;
    border-radius: 10px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;

}

.searchBar input::placeholder {
    font-size: 17px;
    font-weight: 400;
}

.countriesPreSelect {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
}

.countryItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
}

.avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottomBlock {
    width: 100%;
    background: white;
    padding: 16px 16px 32px;
    border-top: 0.3px solid #54545657;
    margin-top: auto;
}

.recentSearchBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.subTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    margin-bottom: 8px;
}

.searchItems {
    background: white;
    padding-left: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.result {
    padding: 11px 11px 11px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.result:not(:last-child) {
    border-bottom: 1px solid #54545657;
}
