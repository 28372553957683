.mainWrapper {
    max-width: 475px;
    margin: 0 auto;
    padding: 16px;
}

.mainWrapperWithKeyboard {
    padding-bottom: 330px;
}

.bottomSpace {
    padding-bottom: 330px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.mainImg {
    width: 73px;
    height: 53px;
}

.title {
    font-family: var(--font-family);
    font-weight: 590;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--main-text);
    margin-top: 16px;
    margin-bottom: 8px;
}

.text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--main-text);
    width: 95%;
}

.wrapper_input {
}

.label {
    margin-top: 32px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: var(--main-text-secondary);
    text-transform: uppercase;

    margin-left: 16px;
    margin-bottom: 8px;
}

.blockGender {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;

    cursor: pointer;
    box-sizing: border-box;

    border-radius: 12px;
    padding: 0px 16px;
    width: 100%;
    height: 44px;
    background: var(--main-bg);

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
}

.defaultGender {
    color: var(--main-text-secondary);
}

.selectedGender {
    color: var(--main-text);
}

.option {
    position: absolute;
    z-index: 10;
    top: 52px;
    right: 0;

    border-radius: 20px;
    width: 192px;
    height: 144px;

    box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.05), 0 0 2px 1px rgba(0, 0, 0, 0.05);
    background: var(--main-bg);
}

.optionItem {
    padding: 12px 16px;
    width: 192px;
    height: 46px;
    box-sizing: border-box;
}

.optionText {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: var(--main-text);
}

.textArea {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    /*height: 84px;*/
    width: 100%;
    resize: none;
    background: var(--main-bg);
    outline: none;
    border: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
}

.textArea::placeholder {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: #A2ACB0;
}

.inputValue {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    letter-spacing: -0.01em;
    color: var(--main-text-secondary);

    margin: 0 0px 8px 16px;
}

.input {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0px 16px;
    width: 100%;
    height: 44px;
    border: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: var(--main-text);
}

.input::placeholder {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: var(--main-hint);
}

.input:focus {
    border: none;
    outline: none;
}

.input.error {
    border: 1px solid #ff4b4b;
}

.errorMessage {
    margin-top: 16px;
    font-size: 14px;
    color: #ff4b4b;
}

.footer {
    position: fixed;
    bottom: 0;
    gap: 8px;
    border-top: 0.33px solid var(--additional-divider);
    padding: 12px 0px 0px 0px;
    width: 100%;
    height: 62px;
    background: var(--main-bg);
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.deleteButton {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 14px 18px;
    margin-top: 24px;

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    color: #E53935;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #00000033;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteModal {
    background: white;
    border-radius: 16px;
    width: 100%;
    max-width: 270px;
}

.popupTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
}

.deleteModal p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin: 0px 16px;
    padding-bottom: 16px;
    text-align: center;
}

.buttons {
    border-top: 0.3px solid #8080808C;
    display: flex;
}

.buttons button {
    width: 50%;
    padding: 12px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.buttons button:first-child {
    border-right: 1px solid #8080808C;
}

.delete {
    color: #E53935;
}

.cancel {
    color: #007AFF;
}

.deletePopup {
    width: 100%;
    position: fixed;
    top: 4px;
    margin: 16px 0px;
    box-sizing: border-box;
    background: #FBE1E1;
    max-width: 345px;
    border-radius: 12px;
    display: flex;
    gap: 8px;
    padding: 16px;
    left: calc(50% - 172.5px);
}

.deletePopup p {
    margin: 0;
    text-align: left;
    padding-bottom: 0;
}
