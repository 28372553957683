.deleteButton {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 14px 18px;
    margin-top: 24px;

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    color: #E53935;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #00000033;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteModal {
    background: white;
    border-radius: 16px;
    width: 100%;
    max-width: 270px;
}

.popupTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
}

.deleteModal p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin: 0px 16px;
    padding-bottom: 16px;
    text-align: center;
}

.buttons {
    border-top: 0.3px solid #8080808C;
    display: flex;
}

.buttons button {
    width: 50%;
    padding: 12px ;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 0;
}

.buttons button:first-child {
    border-right: 1px solid #8080808C;
}

.delete {
    color: #E53935;
}

.cancel {
    color: #007AFF;
}

.deletePopup {
    width: 100%;
    position: fixed;
    top: 4px;
    margin: 16px 0px;
    box-sizing: border-box;
    background: #FBE1E1;
    max-width: 345px;
    border-radius: 12px;
    display: flex;
    gap: 8px;
    padding: 16px;
    left: calc(50% - 172.5px);
}

.deletePopup p {
    margin: 0;
    text-align: left;
    padding-bottom: 0;
}
