.wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.photosTitle{
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;

}
