.globalWrapper {
    width: 100%;
    min-width: 100vw;
    min-height: 100%;
}

.pageWrapper {
    width: 100%;
    min-width: 100vw;


    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    box-sizing: border-box;
    overflow: auto;
}
.mainWrapperWithKeyboard{
    padding-bottom: 330px;
}
.topWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    margin-bottom: 24px;
}

.title {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    max-width: 280px;
    margin: 0 auto;
}

.subtitle {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.bottomBlock {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    padding: 12px 16px 32px 16px;
    z-index: 10;
}

.bottomBlock button {
    margin-top: 0;
}

.recentSearchBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.subTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    margin-bottom: 8px;
}

.recentSearchBlock textarea {
    padding: 16px;
    border: none;
    border-radius: 10px;
    background: white;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    resize: none;
    box-sizing: border-box;
    min-height: 76px;
    font-family: var(--font-family);
}

.recentSearchBlock textarea::placeholder {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #A2ACB0;
    font-family: var(--font-family);
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}
