.wrapper {
    background: #ffffff;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
}

.firstPart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.secondPart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.about {
    display: flex;
    gap: 16px;
    align-items: center;

    flex-basis: 100%;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.place {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.date {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.23000000417232513px;
    text-align: left;
}

.size {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.priceDetail {
    padding-top: 16px;
    border-top: 0.33px solid #000;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.priceDetailKey {

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.priceDetailValue {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    letter-spacing: 0.07000000029802322px;
    text-align: left;

}
