.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 125vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    padding-bottom: 120px;
}

.topWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    margin-bottom: 24px;
}

.priceItemWrapper {
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
}

.controls {
    display: flex;
    gap: 16px;
    margin-top: 16px;
}

.control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    background: #DDE8FF;
    color: #007AFF;
    line-height: 0;
    font-size: 24px;
}


.priceItemTitle {
    font-size: 16px;
    font-weight: 590;
    line-height: 21px;
}

.priceItemDescription{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.parcelItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.parcelSizeItem {
    overflow: hidden;
    background: white;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.parcelSizeItem.active {
    border: 1px solid #007AFF;
}

.parcelItem {
    display: flex;
}

.parcelInfo {
    position: relative;
    margin: 8px 0px;
    padding: 0px 24px 0px 8px;
    border-left: 1px solid #54545657;
    width: 100%;
}

.inputWrapper {
    position: absolute;
    top: 4px;
    right: 12px;
}

.bottomBlock {
    width: 100%;
    padding: 16px 16px 32px 16px;
}
.price{
    font-size: 28px;
    font-weight: 590;
    line-height: 34px;
    letter-spacing: 0.3799999952316284px;
    text-align: left;

}

.footer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 0.33px solid var(--additional-divider);
    padding: 6px 16px 12px 16px;
    width: 100%;
    height: auto;
    gap: 8px;
    background: var(--main-bg);
}
.footer > *{
    width: calc(100% - 32px);
}
