.wrapper {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 82.5px;
  position: relative;
}
.title {
  font-size: 24px;
  font-weight: 590;
  line-height: 28px;
  letter-spacing: 0.07000000029802322px;
  text-align: left;
}
.btn {
  color: #007aff;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
  position: absolute;
  right: 16px;
}
.tracking{
  display: flex;
  flex-direction: column;
}
.parcelCards,
.deliveryTripCards{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 32px;
}
