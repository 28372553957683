.wrapper {
    background: #fff;
    border-radius: 12px;
    padding: 32px 16px;
    margin: 20px 0;
    position: relative;
    height: 296px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.wrapper h2 {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
    margin-bottom: 8px;
    z-index: 10;

}

.wrapper p {
    color: #707579;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.23000000417232513px;
    text-align: center;
    z-index: 10;

}

.img {
    position: absolute;
    top: 0;
    left: 0;
}
.img img{
    max-width: 100%;
}
.wrapper {
}
