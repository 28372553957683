.globalWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
}

.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: calc(100vh - 95px);
    max-height: calc(100vh - 95px);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;
    padding-top: 40px;
    box-sizing: border-box;
    overflow: auto;
}

.topWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    margin-bottom: 32px;
}

.title {

    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    max-width: 280px;
    margin: 0 auto;
    margin-top: 8px;
}

.subtitle {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.parcelItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.parcelSizeItem {
    overflow: hidden;
    background: white;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.parcelSizeItem.active {
    border: 1px solid #007AFF;
}

.parcelItem {
    display: flex;
}
.parcelItem img{
    padding-right: 8px;
}
.parcelInfo {
    position: relative;
    margin: 8px 0px;
    padding: 0px 24px 0px 8px;
    border-left: 1px solid #54545657;
    width: 100%;

}

.parcelInfo :first-child {
    margin-top: 8px;
    font-weight: 590;
    margin-bottom: 4px;
}

.inputWrapper {
    position: absolute;
    top: 4px;
    right: 12px;
}

.bottomBlock {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    background: white;
    padding: 12px 16px 32px 16px;
    border-top: 0.3px solid #54545657;
    z-index: 10;
}

.bottomBlock button {
    margin-top: 0;
}
