.pageWrapper {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    box-sizing: border-box;
}

.topWidget {
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 24px;
    margin-top: 12px;
    position: relative;
}

.parcelSize {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.title {
    font-size: 19px;
    font-weight: 590;
    line-height: 24px;
    display: flex;
    gap: 4px;
}

.title p {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}

.subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    margin-bottom: 8px;
}

.noResults {
    background: white;
    border-radius: 10px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.resultItem {
    min-width: 280px;
    width: 100%;

    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    background: white;
}

.resultsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.resultsWrapper {
    width: 100%;
    margin-bottom: 16px;
}

.resultInfo {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: calc(100% - 48px);
}

.ratingWrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.ratingWrapper span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.infoText {
    display: flex;
    gap: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #707579;
    margin-top: 2px;
}
.name{
    display: flex;
    gap: 8px;
    width: calc(100% - 50px);
}
.courierName {
    width: calc(100% - 50px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name span {
    display: flex;
    gap: 4px;
    align-items: center;
}

.infoText span {
    display: block;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
}

.subtext {
    text-align: center;
    color: #707579;
}

.ratingWrapper span {
    font-weight: 590;
}
