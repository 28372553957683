.pageWrapper {
    padding-bottom: 100px;
    background: #EFEFF4;
}

.content {
    position: relative;
    margin: 0 auto;
    max-width: 475px;
    padding: 16px;
    box-sizing: border-box;
}

.profileAvatarWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topInfoWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
}

.name {
    white-space: nowrap; /* Запретить перенос строк */
    overflow: hidden; /* Скрыть переполнение */
    text-overflow: ellipsis; /* Добавить многоточие */
    max-width: 80%;
}

.profileName {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;

}

.topWidget {
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 24px;
    margin-top: 12px;
    position: relative;
}

.parcelSize {
    position: absolute;
    right: 16px;
    bottom: 66px;
}

.title {
    font-size: 19px;
    font-weight: 590;
    line-height: 24px;
    display: flex;
    gap: 4px;
}

.title p {
    max-width: 40%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}

.rating {
    display: flex;
    gap: 4px;
    margin: 0;
}

.rating span {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    display: flex;
    align-items: center;
}

.edit {
    margin-top: 8px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    color: #007AFF;
    cursor: pointer;
}

.bioBlock {
    background: white;
    border-radius: 12px;
}

.bioBlock p {
    margin: 0;
}

.bio {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0 12px 0;
    margin-left: 16px;
    margin-top: 16px;
    border-bottom: 1px solid #54545657;
}

.bio > p,
.links > p {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.bioDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    padding-right: 12px;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 12px 16px 0;
    margin-left: 16px;
}

.links a {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #007AFF;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.optionalBlock {
    margin-top: 20px;
    background: white;
    border-radius: 12px;
    margin-bottom: 24px;
}

.optionItem {
    padding: 0 0 0 16px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.optionItem:last-child .optionItemBlock {
    border-bottom: none;
}

.optionItemBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 13px 0 12px;
    border-bottom: 1px solid #54545657;
}

.optionItemBlock > p {
    margin: 0;
}

.optionItemBlock svg {
    margin-right: 14px;
}

.bottomBlock {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    background: white;
    padding: 12px 16px 32px 16px;
    border-top: 0.3px solid #54545657;
    z-index: 10;
    position: fixed;
    bottom: 0;
}

.tripInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.dates {
    color: #707579;
}

.detailsWrapper span {
    margin-left: 16px;
}

.detailsWrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #707579;
    margin-bottom: 24px;
}

.detailsWrapper span {
    text-transform: uppercase;
}

.detailsContent {
    word-break: break-word;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    display: flex;
    justify-content: space-between;

    background: #ffffff;
    padding: 16px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.bottomPrice {
    border-top: 0.3px solid #54545657;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
}

.text {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
}

.price {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
}

.courierWrapper {
    padding: 16px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 16px;
    /*width: 100%;*/
    max-width: 100%;

}

.courierInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 72px);
    /*max-width: 90%;*/
}

.ratingWrapper {
    display: flex;
    gap: 4px;
    align-items: center;
}

.ratingWrapper span {
    display: flex;
    align-items: center;
    gap: 8px;
}
