.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.photoItem {
  position: "relative";
  height: 160px;
  border-radius: 12px;
  flex-basis: calc(50% - 4px);
  background: #dde8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.empty {
  position: "relative";
  border: 1px dashed #007aff;
}
.loader {
  width: 24px;
  height: 24px;
  border: 3px solid #007aff; /* Светлая часть */
  border-top: 3px solid #dde8ff; /* Тёмная часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
