.navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 0.3px solid #54545657;
    display: flex;
    justify-content: space-around;
    background: white;
    padding: 7px 0 24px;
    margin: 0 auto;
    max-width: 475px;
    z-index: 101;
    align-items: flex-end;
}

.tabItem {
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
}

.tabItem p {
    font-size: 10px;
    font-weight: 510;
    line-height: 12px;
    color: #999999;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.tab path {
    fill: #999999;
}

.active {
    color: #007AFF!important;
}
