.stars {
    display: flex;
}

.starContainer {
    width: 48px;
    height: 48px;
}

.star {
    cursor: pointer;
    font-size: 36px;
    font-weight: bold;
    color: #54545657;
}

.filled {
    color: #FFCC00;
}
