.mainWrapper {
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.space{
  padding-bottom: 330px;
}
.mainWrapperWithKeyboard{
  padding-bottom: 330px;
}
.mainImg {
  width: 73px;
  height: 53px;
}

.title {
  font-family: var(--font-family);
  font-weight: 590;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--main-text);
  margin-top: 16px;
  margin-bottom: 8px;
}

.text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 131%;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--main-text);
  width: 95%;
}

.wrapper_input {
}

.label {
  margin-top: 32px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--main-text-secondary);
  text-transform: uppercase;

  margin-left: 16px;
  margin-bottom: 8px;
}

.blockGender {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;

  cursor: pointer;
  box-sizing: border-box;

  border-radius: 12px;
  padding: 0px 16px;
  width: 100%;
  height: 44px;
  background: var(--main-bg);

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
}

.defaultGender {
  color: var(--main-text-secondary);
}

.selectedGender {
  color: var(--main-text);
}

.option {
  position: absolute;
  z-index: 10;
  top: 52px;
  right: 0;

  border-radius: 20px;
  width: 192px;
  height: 144px;

  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.05), 0 0 2px 1px rgba(0, 0, 0, 0.05);
  background: var(--main-bg);
}

.optionItem {
  padding: 12px 16px;
  width: 192px;
  height: 46px;
  box-sizing: border-box;
}

.optionText {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-text);
}

.textArea {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  min-height: 84px;
  height: auto;
  width: 100%;
  resize: none;
  background: var(--main-bg);
  outline: none;
  border: none;

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
}

.textArea::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: #A2ACB0;
}

.inputValue {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  letter-spacing: -0.01em;
  color: var(--main-text-secondary);

  margin: 0 0px 8px 16px;
}

.input {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0px 16px;
  width: 100%;
  height: 44px;
  border: none;

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-text);
}

.input::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -0.02em;
  color: var(--main-hint);
}

.input:focus {
  border: none;
  outline: none;
}

.input.error {
  border: 1px solid #ff4b4b;
}

.errorMessage {
  margin-top: 16px;
  font-size: 14px;
  color: #ff4b4b;
}
.footer {

  padding: 12px 0;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

}
.button {
  max-width: 343px;
  width: 100%;
  display: flex;
  justify-content: center;

}
.button button{
  width: 100%;
  margin: 0!important;
}
.desc{
  margin-top: 16px;
  display: flex;
  gap: 8px;
  padding: 16px;
  border-radius: 16px;
  background: var(--main-bg);
}
