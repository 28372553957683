
.cover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: rgb(223, 223, 223);
    border-radius: 16px;
    width: 100%;
    max-width: 270px;
}
.modal p{
    text-align: center;
}
.modal h3{
   margin-top: 20px;
}
.modalTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
}

.modal p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin: 0px 16px;
    padding-bottom: 16px;
    text-align: center;
}

.buttons {
    border-top: 0.3px solid #8080808C;
    display: flex;
}

.buttons button {
    margin: 0;
    width: 50%;
    padding: 12px ;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.buttons button:first-child {
    border-right: 1px solid #8080808C;
}

.delete {
    color: #E53935;
}

.cancel {
    color: #007AFF;
}
