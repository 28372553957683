.photoContainer {
  position: relative;
  height: 160px;
  border-radius: 12px;
  flex-basis: calc(50% - 4px);
  background: #dde8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}
.photo{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.removePhoto{
  position: absolute;
  right: 12.5px;
  top: 12px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
