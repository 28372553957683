.avatar {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-size: cover !important;
}

.s {
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.l {
    min-width: 64px;
    width: 64px;
    height: 64px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}
