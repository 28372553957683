.wrapper {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 160px;
}
.photosTitle{
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;

}
.title {
  font-size: 24px;
  font-weight: 590;
  line-height: 28px;
  letter-spacing: 0.07000000029802322px;
  text-align: left;
}
.btn {
  color: #007aff;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
}
.tracking{
  display: flex;
  justify-content: space-between;
}
.parcelCards,
.deliveryTripCards{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-top: 0.33px solid var(--additional-divider);
  padding: 6px 16px 12px 16px;
  width: 100%;
  height: auto;
  gap: 8px;
  background: var(--main-bg);
}
.footer p{
  width: calc(100% - 32px);
}
.button {
  width: calc(100% - 32px);
  display: flex;
  justify-content: center;
}
.goToChat{
  color: #007AFF;
  padding-bottom: 16px;
  padding-left: 16px;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;

}
