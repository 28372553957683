.star {
    width: 16px;
    height: 16px;
}

.filled path {
    fill: #FFCC00;
}

.empty path {
    fill: #999999;
}

.rating {
    display: flex;
    align-items: center;
}