.pageWrapper {
    width: 100%;
    min-width: 100vw;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.mainWrapperWithKeyboard{
    padding-bottom: 330px;
}
.topWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    margin-bottom: 24px;
}

.subTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 16px;
    text-transform: uppercase;
    color: #707579;
    margin-bottom: 8px;
    text-align: left;
    width: 100%;
    margin-top: 24px;
}

.title {
    font-size: 19px;
    font-weight: 590;
    line-height: 24px;
}

.subTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
}

.destinationWidget {
    background: white;
    border-radius: 10px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.sizeWidget {
    background: white;
    border-radius: 10px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.destination {
    position: relative;
    padding: 11px 40px 11px 0;
    display: flex;
    justify-content: space-between;
}

.destination:not(:last-child) {
    border-bottom: 1px solid #54545657;
}

.destination svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
}

.destination span {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.destinationInfo {
    color: #707579;
}

.subText {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #707579;
    align-self: flex-start;
    padding-left: 16px;
}

.bottomWidget {
    background: white;
    border-radius: 10px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.widgetItem {
    position: relative;
    padding: 11px 40px 11px 0;
    display: flex;
    justify-content: space-between;
}

.widgetItem:first-child {
    border-bottom: 1px solid #54545657;
}

.widgetItem svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
}

.widgetItem span {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.recentSearchBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.recentSearchBlock .textArea {
    padding: 16px;
    border: none;
    border-radius: 10px;
    background: white;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    resize: none;
    box-sizing: border-box;
    min-height: 76px;
    font-family: var(--font-family);
}

.recentSearchBlock textarea::placeholder {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #A2ACB0;
    font-family: var(--font-family);
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.bottomBlock {
    margin-top: auto;
    padding: 16px 16px 32px;
    padding-bottom: 0;
    width: 100%;
}

.subtext {
    padding-left: 16px;
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
    color: #707579;
}

.subtext span {
    cursor: pointer;
    color: #007AFF
}

.size {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 12px 0;
    margin-left: 16px;
    border-bottom: 1px solid #54545657;
}

.size > p,
.info > p{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color:#707579
}

.sizeText {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 12px 16px 0;
    margin-left: 16px;
}
.infoText{
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.sizeWidgetBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;

    border-top: 0.3px solid #54545657
}

.widgetText {
    display: flex;
    align-items: center;
    gap: 6px;

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}

.totalPrice {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
}
.price{

    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;

}
.row{
    display: flex;
    justify-content: space-between;
}
